import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CSRHeroSectionComponent from '../components/csr-page/CSRHeroSectionComponent';
import CSRFeaturedActivitiesComponent from '../components/csr-page/CSRFeaturedActivitiesComponent';
import CSRCultureSectionComponent from '../components/csr-page/CSRCultureSectionComponent';
import CSROtherActivitiesComponent from '../components/csr-page/CSROtherActivitiesComponent';

import alcoholDonationsImage from '../../static/images/csr/activities/csr-alcohol-donations-min.jpg';
import orphanage2Image from '../../static/images/csr/activities/csr-orphanage2-min.jpg';
import houseBuildingImage from '../../static/images/csr/activities/csr-house-building-min.jpg';
import orphanage1Image from '../../static/images/csr/activities/csr-orphanage1-min.jpg';
import brigadaEskwelaImage from '../../static/images/csr/activities/csr-brigada-eskwela-min.jpg';
import treePlantingImage from '../../static/images/csr/activities/csr-tree-planting-min.jpg';
import homeForTheAgedImage from '../../static/images/csr/activities/csr-home-for-the-aged-min.jpg';
import LinesAndNodesComponent from '../components/LinesAndNodesComponent';

const csrActivities = [
  {
    id: 7,
    image: alcoholDonationsImage,
    activity: 'Alcohol Donations',
    tagline: 'Solidarity with the Heroes',
    date: '2020',
    location:
      'Quezon City - East Avenue Medical Center, the National Kidney and Transplant Institute, The Lung Center of the Philippines, and the Quezon City Hall',
    title: 'Alcohol Donations for QC Front-liners',
    details: `To celebrate the 30th birthday of PRIME Philippines CEO Jet Yu on April 1, 2020 in a more meaningful way, the company donated over 200 gallons of alcohol to four QC institutions, namely: East Avenue Medical Center, the National Kidney and Transplant Institute, The Lung Center of the Philippines, and the Quezon City Hall. This simple but timely delivery was all in the hope of better supporting the medical and social frontliners battling the spread of the novel coronavirus in the country and to show solidarity with these selfless heroes.`,
    link: {
      path: '/our-covid-19-response',
      text: 'Check our COVID-19 response',
    },
  },
  {
    id: 6,
    image: orphanage2Image,
    activity: 'Orphanage',
    tagline: 'Giving Joy, Transforming Lives',
    date: 'November 16, 2019',
    location: `Children's Joy Foundation`,
    title: 'An Early Christmas with the Kids',
    details: `On November 16, 2019, PRIME Philippines celebrated an early Christmas with the children at Children's Joy Foundation by spending a fun morning full of games, gifts and food together. Thanks to the help of U! Happy, an organization that links professional organizations with charitable institutions around the Metro, PRIME Philippines employees were able to meaningfully live out the meaning of Christmas by sharing their time and treasures with the energetic and warmhearted children.`,
    link: { path: '', text: '' },
  },
  {
    id: 5,
    image: houseBuildingImage,
    activity: 'House Building/Construction',
    tagline: 'Building Homes, Achieving Dreams, Transforming Lives',
    date: 'October 27, 2018',
    location: 'Pioneer GK Village, Kalusugan St., Batasan, Quezon City',
    title: 'A Day to Build Homes and Dreams',
    details: `Aligned with the company's mission of supporting inclusive growth for all Filipinos, on October 27, 2018, the entire PRIME Philippines team volunteered to build homes at the Pioneer Gawad Kalinga Village in Batasan, Quezon City. In the heat of the day and under the helpful guidance of the GK builders, the PRIME team worked together using a variety of tools and materials to help construct forever homes for the needy. `,
    link: { path: '', text: '' },
  },
  {
    id: 4,
    image: orphanage1Image,
    activity: 'Orphanage',
    tagline: 'Dream Big, Aim High',
    date: 'October 21, 2017',
    location: 'House of Refuge Foundation Inc.',
    title: 'Helping the Youth Envision their Dreams',
    details: `On October 21, 2017, the PRIME Philippines team spent the afternoon volunteering and spending quality time with the resident children of the House of Refuge Foundation, Inc. The afternoon was filled with fun games and gift giving, as well as a special activity where PRIME Philippines employees helped the kids envision and draw their hopes and dreams.`,
    link: { path: '', text: '' },
  },
  {
    id: 3,
    image: brigadaEskwelaImage,
    activity: 'Brigada Eskwela',
    tagline: 'Showing Care through Colorful Spaces',
    date: 'June 1, 2016',
    location: 'Sto. Cristo Elementary School',
    title: 'Preparing Schoolrooms and Spaces for the Students',
    details: `Supporting the company's ideals of accessible learning and conducive spaces for all, the PRIME Philippines team participated in the 2016 Brigada Eskwela initiative at the Sto. Cristo Elementary School. Alongside other volunteers, PRIME employees painted the walkway and school walls using paint donated by the company, just in time for the official start of the school year.`,
    link: { path: '', text: '' },
  },
  {
    id: 2,
    image: treePlantingImage,
    activity: 'Tree Planting',
    tagline: '300 Trees: One Team, One Big Difference',
    date: 'October 10, 2015',
    location: 'Brgy. Talampas, Bustos, Bulacan',
    title: 'Roadside Tree-Planting for the Community',
    details: `In line with the company's hope of a better future for the Philippines, on October 10, 2015, the PRIME Philippines team spent the day planting 300 tree saplings in Brgy. Talampas, Bustos, Bulacan. In partnership with Save the Planet, the team planted the trees along the roadside in the hopes of making big difference to the surrounding landscape and nearby communities in the future.`,
    link: { path: '', text: '' },
  },
  {
    id: 1,
    image: homeForTheAgedImage,
    activity: 'Home for the Aged',
    tagline: '',
    date: 'December 23, 2014',
    location: `God's Grace Home Care Center`,
    title: 'A Holiday Celebration with the Elderly',
    details: `In the spirit of selflessness and giving back, PRIME Philippines held their first CSR event on December 23, 2014 with the residents of God's Grace Home Care Center. In the simple yet meaningful Christmas celebration, the team hoped to impart a warm and special memory with the elderly residents of the care home, while also experiencing for themselves the true spirit of Christmas.`,
    link: { path: '', text: '' },
  },
];

const StyledDivWrapper = styled.div`
  padding-top: 89px;
`;

function CSRPage({ location }) {
  return (
    <Layout source="CSR Page">
      <SEO
        title="Corporate Social Responsibility"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <StyledDivWrapper>
        <Row type="flex" justify="center">
          <Col xxl={16} sm={24} xs={24}>
            <div className="line-node-wrapper">
              <LinesAndNodesComponent />
              <CSRHeroSectionComponent />
              <CSRFeaturedActivitiesComponent
                csrActivities={csrActivities.slice(0, 3)}
              />
              <LinesAndNodesComponent set={1} position="right" />
            </div>

            <CSRCultureSectionComponent />

            <div className="line-node-wrapper">
              <LinesAndNodesComponent set={1} />

              <CSROtherActivitiesComponent
                csrActivities={csrActivities.slice(3)}
              />
              <LinesAndNodesComponent style={{ zIndex: -1 }} position="right" />
            </div>
          </Col>
        </Row>
      </StyledDivWrapper>
    </Layout>
  );
}

CSRPage.propTypes = {
  location: PropTypes.object,
};
export default CSRPage;
