import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDiv = styled.div`
  margin: 32px 0px;

  .styled-content {
    width: 100%;

    .tagline {
      margin-top: 16px;
      margin-bottom: 4px;
      font-style: italic;

      ::after {
        font-style: normal;
        content: '“';
        font-size: 6em;
        position: absolute;
        top: 208px;
        left: 12px;
        z-index: -1;
        opacity: 0.4;
        color: ${props => props.theme.primeYellowPalette.original};

        @media screen and (max-width: 767px) {
          top: 200px;
          left: 0px;
        }
      }
    }

    h3 {
      margin-bottom: 4px;
      color: ${props => props.theme.primeBluePalette.original};
    }

    .ant-divider {
      margin: 16px 0px;
    }

    h5 {
      margin-bottom: 4px;
      font-weight: 400;
    }

    .date-location {
      font-size: 14px;
    }

    .styled-link {
      margin-top: 16px;

      a {
        color: ${props => props.theme.bluePalette.original};
        font-weight: 400;

        .styled-icon {
          margin-left: 8px;
        }
      }
    }
  }
`;

const StyledImage = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 220px;
  margin-bottom: 8px;
`;

function CSROtherActivitiesComponent({ csrActivities }) {
  return (
    <StyledDiv>
      <Row type="flex" justify="center">
        <Col md={22} sm={23} xs={23}>
          <Row
            type="flex"
            gutter={[
              { md: 42, sm: 16, xs: 16 },
              { md: 42, sm: 24, xs: 24 },
            ]}
          >
            {csrActivities.map(value => (
              <Col
                md={12}
                sm={24}
                xs={24}
                key={value.id}
                className="styled-content"
              >
                <StyledImage image={value.image} />
                {value.tagline && (
                  <div className="tagline">{value.tagline}</div>
                )}
                <h3>{value.title}</h3>
                <p>{value.details}</p>
                {value.details && <Divider />}
                <h5>{value.activity}</h5>
                <div className="date-location">
                  {value.date} - {value.location}
                </div>
                {value.link.path && (
                  <div className="styled-link">
                    <Link to={value.link.path}>
                      {value.link.text}{' '}
                      <FontAwesomeIcon
                        icon={['fas', 'arrow-right']}
                        className="styled-icon"
                      />
                    </Link>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </StyledDiv>
  );
}

export default CSROtherActivitiesComponent;
