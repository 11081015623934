import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const StyledDiv = styled.div`
  margin: 32px 0px;

  .styled-content {
    .tagline {
      margin-top: 12px;
      margin-bottom: 4px;
      font-style: italic;

      ::after {
        font-style: normal;
        content: '“';
        font-size: 6em;
        position: absolute;
        top: -32px;
        left: 0;
        z-index: -1;
        opacity: 0.4;
        color: ${props => props.theme.primeYellowPalette.original};
      }
    }

    h3 {
      margin-bottom: 4px;
      color: ${props => props.theme.primeBluePalette.original};
    }

    .ant-divider {
      margin: 16px 0px;
    }

    h5 {
      margin-bottom: 4px;
      font-weight: 400;
    }

    .date-location {
      font-size: 14px;
    }

    .styled-link {
      margin-top: 16px;

      a {
        color: ${props => props.theme.bluePalette.original};
        font-weight: 400;

        .styled-icon {
          margin-left: 8px;
        }
      }
    }
  }
`;

const StyledImage = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;

  @media only screen and (max-width: 767px) {
    height: 220px;
  }
`;

function CSRFeaturedActivitiesComponent({ csrActivities }) {
  return (
    <StyledDiv>
      <Row type="flex" justify="center">
        <Col md={22} sm={23} xs={23}>
          <Row type="flex" gutter={[16, { md: 64, sm: 24, xs: 24 }]}>
            {csrActivities.map(value => {
              const content = (
                <div className="styled-content">
                  {value.tagline && (
                    <div className="tagline">{value.tagline}</div>
                  )}
                  <h3>{value.title}</h3>
                  <p>{value.details}</p>
                  {value.details && <Divider />}
                  <h5>{value.activity}</h5>
                  <div className="date-location">
                    {value.date} - {value.location}
                  </div>
                  {value.link.path && (
                    <div className="styled-link">
                      <Link to={value.link.path}>
                        {value.link.text}{' '}
                        <FontAwesomeIcon
                          icon={['fas', 'arrow-right']}
                          className="styled-icon"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              );

              return (
                <Col span={24} key={value.id}>
                  {value.id % 2 === 0 ? (
                    <Row type="flex" gutter={[16, 8]} className="styled-row">
                      <Col xl={10} md={12} sm={24} xs={24}>
                        <StyledImage image={value.image} />
                      </Col>
                      <Col xl={14} md={12} sm={24} xs={24}>
                        {content}
                      </Col>
                    </Row>
                  ) : (
                    <Row type="flex" gutter={[16, 8]} className="styled-row">
                      <Col
                        xl={{ span: 14, order: 1 }}
                        md={{ span: 12, order: 1 }}
                        sm={{ span: 24, order: 2 }}
                        xs={{ span: 24, order: 2 }}
                      >
                        {content}
                      </Col>
                      <Col
                        xl={{ span: 10, order: 2 }}
                        md={{ span: 12, order: 2 }}
                        sm={{ span: 24, order: 1 }}
                        xs={{ span: 24, order: 1 }}
                      >
                        <StyledImage image={value.image} />
                      </Col>
                    </Row>
                  )}
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </StyledDiv>
  );
}

CSRFeaturedActivitiesComponent.propTypes = {
  csrActivities: PropTypes.array,
};
export default CSRFeaturedActivitiesComponent;
