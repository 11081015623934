import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const bgImageSet = [
  '../../images/ln-bg-set-1.png',
  '../../images/ln-bg-set-2.png',
  '../../images/ln-bg-set-3.png',
  '../../images/ln-bg-set-4.png',
];

const Wrapper = styled.div`
  position: absolute;

  width: ${props => props.width};
  height: ${props => props.height};
  background-image: ${props => `url(${bgImageSet[props.set]})`};
  background-size: contain;
  mix-blend-mode: multiply;
  opacity: 0.2;
  top: 0;
  background-repeat: repeat-y;

  /* media query */
  @media only screen and (max-width: 550px) {
    background-size: contain !important;
    width: calc(${props => props.width} * 0.6) !important;
  }

  @media only screen and (min-width: 550px) and (max-width: 1024px) {
    width: calc(${props => props.width} * 0.8) !important;
  }
`;

function LinesAndNodesComponent({ style, position, width, height, set }) {
  return (
    <Wrapper
      height={height}
      width={width}
      set={set}
      style={{
        [position.toLowerCase()]: 0,
        transform: `scaleX(${position.toLowerCase() === 'left' ? 1 : -1})`,
        zIndex: -1,
        ...style,
      }}
    />
  );
}

LinesAndNodesComponent.defaultProps = {
  position: 'left',
  height: '100%',
  width: '225px',
  set: 3,
};

LinesAndNodesComponent.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string,
  height: PropTypes.string,
  set: PropTypes.number,
  style: PropTypes.object,
};

export default LinesAndNodesComponent;
