import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import coverImage from '../../../static/images/csr/csr-culture-image-min.jpg';

const StyledDiv = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.28), rgba(0, 0, 0, 0.8)),
    url(${coverImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 480px;

  .styled-row {
    height: 100%;
    color: ${props => props.theme.whitePalette.original};
    h2 {
      text-align: center;
    }
  }
`;

function CSRCultureSectionComponent() {
  return (
    <StyledDiv>
      <Row type="flex" justify="center" align="middle" className="styled-row">
        <Col lg={10} md={16} sm={20} xs={20}>
          <h2>
            It's part of the PRIME culture to give back and make an impact to
            the community we are in today.
          </h2>
        </Col>
      </Row>
    </StyledDiv>
  );
}

export default CSRCultureSectionComponent;
