import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';

import coverImage from '../../../static/images/csr/csr-background.jpg';

const StyledDiv = styled.div`
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.28),
      rgba(0, 0, 0, 0.8)
    ),
    url(${coverImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  max-height: 450px;
  max-width: 1440px;
  margin: auto;
  position: relative;
  margin-bottom: 100px;

  .styled-row {
    height: 100%;
    color: ${props => props.theme.whitePalette.original};
  }

  .headline {
    h1 {
      font-size: 2.75rem;
      font-weight: bolder;
      @media only screen and (max-width: 426px) {
        font-size: 2.3rem;
      }

      @media only screen and (min-width: 550px) and (max-width: 776px) {
        font-size: 2.3rem;
      }
    }
    h1:first-child {
      margin-bottom: 0px;
    }
    h1:last-child {
      color: ${props => props.theme.yellowPalette.original};
    }
  }

  img {
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.41);
    margin-bottom: 0px;
  }

  p {
    font-size: 1.35em;
    line-height: 1.3;

    @media only screen and (min-width: 550px) and (max-width: 776px) {
      font-size: 1.15em;
      line-height: 1;
    }
  }

  .header-action-buttons {
    position: relative;
    bottom: 0px;
    margin-top: -40px;
    max-width: 1440px;
    overflow: hidden;
    padding-bottom: 5px;

    .action-button {
      > div {
        background: ${props => props.theme.whitePalette.original};
        color: ${props => props.theme.primeBluePalette.original};
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
        width: 300px;
        height: 100%;

        padding: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        :hover {
          background: ${props => props.theme.primeBluePalette.original};
          h3 {
            color: ${props => props.theme.primeYellowPalette.original};
          }
        }
      }
      > .active {
        background: ${props => props.theme.primeBluePalette.original};
        h3 {
          color: ${props => props.theme.primeYellowPalette.original};
        }
      }
    }
  }

  h3 {
    margin-bottom: 0px;
    font-weight: bolder;
  }
  @media only screen and (max-width: 426px) {
    height: 50vh;
  }
`;

function CSRHeroSectionComponent() {
  return (
    <StyledDiv>
      <Row type="flex" justify="center" className="styled-row">
        <Col md={22} sm={23} xs={21}>
          <Row
            type="flex"
            align="middle"
            justify="center"
            className="styled-row"
            gutter={[30, 20]}
          >
            <Col xxl={10} xl={12} lg={11} md={12} sm={24} xs={24}>
              <div className="headline">
                <h1>Our Commitment</h1>
                <h1>Beyond Real Estate</h1>
              </div>
              <p>
                Together with our partners we aim to drive disruptive and
                impactful change by embedding sustainability in to everything we
                do.
              </p>
            </Col>

            <Col xxl={8} xl={10} lg={11} md={10} sm={24} xs={0}>
              <img
                src="../../images/csr/csr-foreground.jpg"
                alt="Corporate Social Responsibility"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="header-action-buttons">
        <Row type="row" justify="center" gutter={[20, 0]} align="stretch">
          <Col className="action-button">
            <div>
              <Link to="/city-enabling">
                <h3>CITY ENABLING</h3>
              </Link>
            </div>
          </Col>
          <Col className="action-button ">
            <div className="active">
              <Link to="/corporate-social-responsibility">
                <h3>CORPORATE SOCIAL RESPONSIBILITY</h3>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </StyledDiv>
  );
}

export default CSRHeroSectionComponent;
